// Generate for component TagEdit
import React, { useState } from "react";
import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Tag } from "src/@generated/graphql";
import { useTranslation } from "react-i18next";

import { useMutation, useApolloClient } from "@apollo/client";
import { CREATE_TAG, GET_TAGS, UPDATE_TAG } from "./services/tags.gql";
import { useSelector } from "react-redux";
import { isValidTag } from "./services/helpers";

interface TagEditProps {
  selectedTag: Tag;
  afterSave: () => void;
}

const TagEdit: React.FC<TagEditProps> = ({ selectedTag, afterSave }) => {
  const { t } = useTranslation();
  const { client, id } = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );
  const apolloClient = useApolloClient();

  const [tag, setTag] = useState<TagEditProps["selectedTag"]>(
    { ...selectedTag } ?? {
      clientId: client,
      name: null,
      type: ["INTERVIEWER"],
      archived: false,
      id: undefined
    }
  );
  const [error, setError] = useState<string | null>(null);
  const [createTag, { data, loading: tagCreateProcessing }] = useMutation(
    CREATE_TAG,
    {
      refetchQueries: [GET_TAGS]
    }
  );

  const [updateTag, { loading: tagUpdateProcessing, error: updateTagError }] =
    useMutation(UPDATE_TAG, {
      refetchQueries: [GET_TAGS],
      awaitRefetchQueries: true,
      onCompleted: () => {
        apolloClient.cache.evict({ fieldName: "tags" });
        apolloClient.cache.gc();
      }
    });

  const onSave = () => {
    if (tag && tag.name && tag.type) {
      if (tag.id) {
        updateTag({
          variables: {
            id: tag.id,
            data: {
              name: tag.name?.trim(),
              type: tag.type
            }
          }
        })
          .then(afterSave)
          .catch((e) => setError(e?.message));
      } else {
        createTag({
          variables: {
            input: {
              clientId: tag.clientId || client,
              name: tag.name?.trim(),
              type: tag.type,
              archived: false,
              createdBy: tag.createdBy ?? id
            }
          }
        })
          .then(afterSave)
          .catch((e) => {
            setError(e?.message);
          });
      }
    }
  };

  const onTagNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error === "DUPLICATE_TAG") {
      setError(null);
    }
    const isValid = isValidTag(e.target.value);
    if (!isValid) {
      setError("INVALID_TAG_NAME");
    } else {
      setError(null);
    }
    setTag({ ...tag, name: e.target.value });
  };

  return (
    <Stack direction="column" spacing={2} flex={1} m={1} width={600}>
      {error === "DUPLICATE_TAG" && (
        <Alert severity="error">
          {t("ROOSTER.TAGS.DUPLICATE_TAG", {
            defaultValue: "Tag with this name already exists."
          })}
        </Alert>
      )}
      {error === "INVALID_TAG_NAME" && (
        <Alert severity="error">
          {t("ROOSTER.TAGS.INVALID_TAG_NAME", {
            defaultValue:
              "Invalid tag name. Please ensure the input is between 3 to 50 characters, starts and ends with a letter or number, and only contains letters, numbers, spaces, underscores (_), and hyphens (-)."
          })}
        </Alert>
      )}

      <Typography variant="h5">
        {tag?.id
          ? t("ROOSTER.TAGS.EDIT-TAG", {
              defaultValue: "Edit Tag"
            })
          : t("ROOSTER.TAGS.CREATE-NEW-TAG", {
              defaultValue: "Create New Tag"
            })}
      </Typography>
      <Stack m={2} spacing={2}>
        {/* Meeting duration */}
        <FormControl variant="outlined">
          <FormLabel id="tag-name" sx={{ fontSize: 12 }}>
            {t("ROOSTER.COMMON.NAME", {
              defaultValue: "Name"
            })}
          </FormLabel>
          <TextField
            size={"small"}
            value={tag?.name}
            onChange={onTagNameChange}
          />
        </FormControl>
        <FormControl variant="outlined">
          <FormLabel id="tag-type" sx={{ fontSize: 12 }}>
            {t("ROOSTER.JOB-APPLICATIONS.APPLICATION_TYPE", {
              defaultValue: "Type"
            })}
          </FormLabel>
          <Select
            multiple={true}
            disabled={true}
            size={"small"}
            value={tag?.type}
            defaultValue={["INTERVIEWER"]}
            onChange={(e) =>
              setTag({
                ...tag,
                type:
                  typeof e.target?.value === "string"
                    ? e.target?.value.split(",")
                    : e.target?.value
              })
            }
          >
            <MenuItem value="INTERVIEWER">
              {t("ROOSTER.COMMON.INTERVIEWER", {
                defaultValue: "Interviewer"
              })}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            afterSave && afterSave();
          }}
        >
          {t("ROOSTER.COMMON.CANCEL", {
            defaultValue: "Cancel"
          })}
        </Button>
        <Button
          size="small"
          disabled={
            tagUpdateProcessing ||
            tagCreateProcessing ||
            !tag?.name ||
            !tag?.type ||
            error === "INVALID_TAG_NAME"
          }
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          {t("ROOSTER.COMMON.SAVE", {
            defaultValue: "Save"
          })}
        </Button>
      </Stack>
    </Stack>
  );
};

export default TagEdit;
