import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { FIREBASE_AUTH_LOADING_SET } from "../../reducers/sessionReducer";
import { withFirebase } from "../Firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser) => {
          this.props.onSetAuthUser(authUser);
          this.props.onSetFirebaseAuthLoading(false);
        },
        () => {
          this.props.onSetAuthUser(null);
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch({ type: "AUTH_USER_SET", authUser }),
    onSetFirebaseAuthLoading: (loading) =>
      dispatch({ type: FIREBASE_AUTH_LOADING_SET, payload: loading })
  });

  return compose(
    withFirebase,
    connect(null, mapDispatchToProps)
  )(WithAuthentication);
};

export default withAuthentication;
