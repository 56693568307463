import HistoryIcon from "@mui/icons-material/History";
import { Tooltip, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import ChangeLoggerModal from "./entityChangeLog.list.component";

const ChangeLoggerButton = ({
  entityName,
  tooltipText = "",
  icon,
  buttonProps,
  columns
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltipText || "View Changes"}>
        <IconButton {...buttonProps} onClick={handleOpenModal}>
          {icon || <HistoryIcon />}
        </IconButton>
      </Tooltip>
      <ChangeLoggerModal
        open={isModalOpen}
        onClose={handleCloseModal}
        entityName={entityName}
        columns={columns}
      />
    </>
  );
};

ChangeLoggerButton.propTypes = {
  entityName: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  buttonProps: PropTypes.object,
  icon: PropTypes.node
};

export default ChangeLoggerButton;
