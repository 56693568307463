import { create } from "zustand";
import { Client } from "@rooster/prisma";

interface ClientFeatureStore {
  clientFeatures: Client.Feature;
  setClientFeatures: (permission: Client.Feature) => void;
  hasClientFeature: (feature: string) => boolean;
  fetchClientFeatures: () => Promise<void>;
}

export const clientFeatureStore = create<ClientFeatureStore>(
  (setStore, getStore) => ({
    clientFeatures: {},
    setClientFeatures: (features) => setStore({ clientFeatures: features }),
    hasClientFeature: (feature) => {
      const { clientFeatures } = getStore();
      return clientFeatures?.[feature] ?? false;
    }
  })
);
