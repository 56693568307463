import { useMemo } from "react";
import { useSelector } from "react-redux";
import * as ROUTES from "../constants/routes";
import * as ROLES from "../constants/roles";
import { useUserLocalStore } from "../store/user-local-store";
import { useMyPermissions } from "./usePermissions";
import {
  hasAccessToAnalytics,
  hasAccessToDashboard,
  hasAccessToEmailTemplates,
  hasAccessToInterviewersDashboard,
  hasAccessToPreferences,
  hasAccessToRoosterLinks,
  hasAccessToProspects,
  hasAccessToSettings,
  hasAccessToUsersSection,
  hasOnlyMeetingSchedulerRole,
  hasOnlySourcerRole
} from "../reducers/roleAccessReducer";

export function useUserDefaultRoute() {
  const userPreferredDefaultRoute = useUserLocalStore(
    (state) => state?.preferences?.defaultDashboardView
  );

  const authUser = useSelector(
    (state: any) => state?.sessionState?.authUser ?? {}
  );

  const clientDocData = useSelector(
    (state: any) => state?.clientDocDataState?.clientDocData
  );
  const isStandaloneClient = clientDocData?.isStandalone ?? false;

  // Memoize these calculations
  const permissions = useMemo(() => {
    if (!authUser) {
      return {
        canAccessDashboard: false,
        canAccessProspects: false,
        canAccessRoosterLinks: false
      };
    }

    return {
      canAccessDashboard: hasAccessToDashboard(authUser),
      canAccessProspects: hasAccessToProspects(authUser),
      canAccessRoosterLinks: hasAccessToRoosterLinks(authUser)
    };
  }, [authUser]);

  const defaultRoute = useMemo(() => {
    // console.log(
    //   "useUserDefaultRount: ",
    //   JSON.stringify({
    //     authUser,
    //     permissions,
    //     userPreferredDefaultRoute
    //   })
    // );
    if (isStandaloneClient) {
      return ROUTES.INTERVIEWS_CALENDAR;
    }

    if (!authUser || !authUser?.email) {
      return userPreferredDefaultRoute || ROUTES.JOBS;
    }
    const routePriority = [
      {
        canAccess: permissions.canAccessDashboard,
        routes: [ROUTES.JOBS, ROUTES.INTERVIEWS_CALENDAR]
      },
      {
        canAccess: permissions.canAccessProspects,
        routes: [ROUTES.INTERVIEWS_CALENDAR, ROUTES.PROSPECTLINKS_BOOKINGS]
      },
      {
        canAccess: permissions.canAccessRoosterLinks,
        routes: [ROUTES.INTERVIEWS_CALENDAR, ROUTES.ROOSTERLINKS_BOOKINGS]
      }
    ];

    for (const { canAccess, routes } of routePriority) {
      if (canAccess) {
        return routes.includes(userPreferredDefaultRoute ?? "")
          ? userPreferredDefaultRoute
          : routes[0];
      }
    }
    return ROUTES.INTERVIEWS_CALENDAR;
  }, [authUser, userPreferredDefaultRoute, permissions]);

  return defaultRoute;
}

export function useRouteAccessStatus() {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const clientDocData = useSelector(
    (state: any) => state?.clientDocDataState?.clientDocData
  );
  const { canUserSomeAction } = useMyPermissions();

  const routeAccessMap = useMemo(() => {
    const roosterLinksEnabled = clientDocData?.settings?.pml ?? false;

    const hasTagsAccess = canUserSomeAction({
      feature: "tags",
      actions: [
        "ADD_TAGS",
        "ARCHIVE_TAGS",
        "UNARCHIVE_TAGS",
        "BULK_IMPORT_TAGS"
      ]
    });

    const getAnalyticsVisibility = (authRoles, targetRole) => {
      return Object.keys(authRoles || {}).some(
        (role) => ROLES.HIERARCHY[role] >= ROLES.HIERARCHY[targetRole]
      );
    };

    const isAnalyticsVisible = getAnalyticsVisibility(
      authUser?.roles,
      clientDocData?.settings?.analytics_visibility
    );

    const isStandaloneClient = clientDocData?.isStandalone ?? false;

    const value = {
      [ROUTES.JOBS]: !isStandaloneClient && hasAccessToDashboard(authUser),
      [ROUTES.INTERVIEWS_CALENDAR]: true,
      [ROUTES.ANALYTICS]: isAnalyticsVisible || hasAccessToAnalytics(authUser),
      [ROUTES.PROSPECTLINKS_BOOKINGS]: hasAccessToProspects(authUser),
      [ROUTES.PROSPECTLINKS_DASHBOARD]: !hasOnlyMeetingSchedulerRole(authUser),
      [ROUTES.ROOSTERLINKS_DASHBOARD]: !hasOnlySourcerRole(authUser),
      [ROUTES.ROOSTERLINKS_BOOKINGS]:
        roosterLinksEnabled && hasAccessToRoosterLinks(authUser),
      [ROUTES.EMAILTEMPLATES]: hasAccessToEmailTemplates({
        authUser,
        clientDocData: clientDocData
      }),
      [ROUTES.INTERVIEWERSDASHBOARD]:
        hasAccessToInterviewersDashboard(authUser),
      [ROUTES.USERS]: hasAccessToUsersSection(authUser),
      [ROUTES.TAGS_DASHBOARD]: hasTagsAccess,
      [ROUTES.PREFERENCES]: hasAccessToPreferences(authUser),
      [ROUTES.SETTINGS]: hasAccessToSettings(authUser),
      [ROUTES.INTERVIEWERS_AVAILABILITY]: hasAccessToDashboard(authUser)
    };
    return value;
  }, [authUser, clientDocData, canUserSomeAction]);

  return routeAccessMap;
}
