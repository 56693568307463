import { create } from "zustand";
import { Permissions } from "@rooster/prisma";

type UserPermission = Pick<Permissions, "resource" | "action">;

interface PermissionStore {
  permissions: UserPermission[];
  hasPermission: (action: string) => boolean;
  fetchPermissions: () => Promise<void>;
}

export const userPermissionStore = create<PermissionStore>(
  (setStore, getStore) => ({
    permissions: [],
    hasPermission: (action) => {
      const { permissions } = getStore();
      return permissions.some(
        (permission) => permission.action.toLowerCase() === action.toLowerCase()
      );
    },
    savePermissions: (permissions) => {
      setStore({ permissions });
    }
  })
);
