export const SUPER_ADMIN = "SUPER_ADMIN";
export const TENANT_ADMIN = "TENANT_ADMIN";
export const SCHEDULING_ADMIN = "SCHEDULING_ADMIN";
export const SCHEDULER = "SCHEDULER";
export const SCHEDULER_ONLY = "SCHEDULER_ONLY";
export const MEETING_SCHEDULER = "MEETING_SCHEDULER";
export const INTERVIEWER = "Interviewer";
export const CANDIDATE = "Candidate";
export const SOURCER = "IPP_USER";
export const IAM_ROLES = {
  [TENANT_ADMIN]: "Tenant Admin",
  [SCHEDULING_ADMIN]: "Scheduling Admin",
  [SCHEDULER]: "Scheduler",
  [SCHEDULER_ONLY]: "Scheduler Only",
  [SOURCER]: "Sourcer",
  [MEETING_SCHEDULER]: "Meeting Scheduler",
};

export const HIERARCHY = {
  [TENANT_ADMIN]: 4,
  [SCHEDULING_ADMIN]: 3,
  [SCHEDULER]: 2,
  [SCHEDULER_ONLY]: 1,
  [SOURCER]: 0,
  [MEETING_SCHEDULER]: 0,
};

// This hiearchy is just used for sorting roles
export const HIERARCHY_SORT = {
  [TENANT_ADMIN]: 16,
  [SCHEDULING_ADMIN]: 8,
  [SCHEDULER]: 4,
  [SCHEDULER_ONLY]: 2,
  [SOURCER]: 1,
  [MEETING_SCHEDULER]: 0
};
