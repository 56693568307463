import {
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscResource
} from "@mobiscroll/react";
import moment from "moment-timezone";
import { uniqBy } from "lodash";

interface ResourceDetails {
  [key: string]: {
    colors: MbscCalendarColor[];
  };
}

/**
 * Checks if two calendar events have the same start and end times
 */
export const isSameEvent = (
  event1: MbscCalendarEvent,
  event2: MbscCalendarEvent
): boolean => {
  if (!event1 || !event2) return false;
  return (
    moment(event1.start).valueOf() === moment(event2.start)?.valueOf() &&
    moment(event1.end).valueOf() === moment(event2.end)?.valueOf()
  );
};

/**
 * Find common available slots that meet the required duration
 * @param fixedResources - Array of selected resources
 * @param fixedResourceDetails - Resource availability details
 * @param considerCandidateAvailability - Whether to consider candidate's availability
 * @param duration - Required duration in minutes
 * @param eventDuration - Minimum time slot duration (15 or 30 min)
 * @returns Array of continuous availability blocks
 */
export const getCommonAvailableSlots = (
  fixedResources: MbscResource[],
  fixedResourceDetails: ResourceDetails,
  considerCandidateAvailability: boolean,
  duration: number,
  eventDuration: number
): MbscCalendarColor[] => {
  // If no resources are selected or there are no resources with availability to consider, return empty array
  if (
    !fixedResources?.filter(
      (fr) => considerCandidateAvailability || !fr.candidate
    )?.length
  ) {
    return [];
  }

  // Get common available slots (intersection of all resources' availability)
  let availableSlots: any = null;
  fixedResources
    ?.filter((fr) => considerCandidateAvailability || !fr.candidate)
    ?.forEach((resource) => {
      const resourceDetails = fixedResourceDetails[resource.id];
      if (resourceDetails) {
        const resourceColors = resourceDetails?.colors?.filter(
          (color) => !!color.available
        );
        if (availableSlots === null) {
          availableSlots = [...resourceColors];
        } else {
          availableSlots = availableSlots?.filter((slot) => {
            return resourceColors?.some((color) => {
              return isSameEvent(color, slot);
            });
          });
        }
      }
    });

  // If no common available slots, return empty array
  if (!availableSlots?.length) {
    return [];
  }

  // Sort all common slots chronologically
  const sortedSlots = [...availableSlots].sort(
    (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
  );

  // Get the required duration in minutes (from props)
  const requiredDurationMinutes = duration;

  // Will hold our final result - continuous blocks meeting the duration requirement
  const continuousBlocks: MbscCalendarColor[] = [];

  // Find continuous blocks of availability
  let currentBlockStart: Date | null | undefined = null;
  let currentBlockEnd: Date | null | undefined = null;
  let currentDuration = 0;

  for (let i = 0; i < sortedSlots.length; i++) {
    const currentSlot = sortedSlots[i];
    const currentSlotDuration = moment(currentSlot.end).diff(
      moment(currentSlot.start),
      "minutes"
    );

    if (!currentBlockStart) {
      // Start a new block
      currentBlockStart = currentSlot.start;
      currentBlockEnd = currentSlot.end;
      currentDuration = currentSlotDuration;
    } else {
      // Check if this slot is continuous with the current block
      // (either exactly adjacent or with a small gap that's within some tolerance)
      const gapToNextSlot = moment(currentSlot.start).diff(
        moment(currentBlockEnd),
        "minutes"
      );
      const isWithinTolerance = gapToNextSlot <= 0; // No gap or overlapping slots

      if (isWithinTolerance) {
        // This slot is continuous with the current block
        // Update the end time if this slot extends further
        if (moment(currentSlot.end).isAfter(moment(currentBlockEnd))) {
          currentBlockEnd = currentSlot.end;
        }
        // Update the total duration of the continuous block
        currentDuration = moment(currentBlockEnd).diff(
          moment(currentBlockStart),
          "minutes"
        );
      } else {
        // This slot is not continuous with the current block
        // Check if the completed block meets the duration requirement
        if (currentDuration >= requiredDurationMinutes) {
          // If it does, add it to our results
          continuousBlocks.push({
            start: currentBlockStart,
            end: new Date(
              moment(currentBlockStart)
                .add(requiredDurationMinutes, "minutes")
                .valueOf()
            ),
            title: `Available for ${requiredDurationMinutes} mins`,
            background: "#c9e8d1",
            available: true,
            resource: currentSlot.resource
          });
        }

        // Start a new block with the current slot
        currentBlockStart = currentSlot.start;
        currentBlockEnd = currentSlot.end;
        currentDuration = currentSlotDuration;
      }
    }

    // For each continuous block that meets the duration requirements,
    // we add a potential slot starting at each position within that block
    // (as long as there's enough time left in the block)
    if (currentDuration >= requiredDurationMinutes) {
      continuousBlocks.push({
        start: currentBlockStart as Date,
        end: new Date(
          moment(currentBlockStart)
            .add(requiredDurationMinutes, "minutes")
            .valueOf()
        ),
        title: `Available for ${requiredDurationMinutes} mins`,
        background: "#c9e8d1",
        available: true,
        resource: currentSlot.resource
      });

      // If the slot is significantly longer than needed, we can create additional starting points
      // This creates more flexibility in scheduling while maintaining the required duration
      const extraTime = currentDuration - requiredDurationMinutes;
      if (extraTime >= eventDuration) {
        // If we have at least one more slot duration of extra time
        // Create additional potential starting points at eventDuration intervals
        const additionalSlots = Math.floor(extraTime / eventDuration);
        for (let j = 1; j <= additionalSlots; j++) {
          const newStart = moment(currentBlockStart)
            .add(j * eventDuration, "minutes")
            .toDate();
          const newEnd = moment(newStart)
            .add(requiredDurationMinutes, "minutes")
            .toDate();

          // Make sure we don't exceed the block end time
          if (moment(newEnd).isSameOrBefore(moment(currentBlockEnd))) {
            continuousBlocks.push({
              start: newStart,
              end: newEnd,
              title: `Available for ${requiredDurationMinutes} mins`,
              background: "#c9e8d1",
              available: true,
              resource: currentSlot.resource
            });
          }
        }
      }
    }
  }

  // Check if the last block meets the requirements
  if (currentBlockStart && currentDuration >= requiredDurationMinutes) {
    // This final check is necessary in case the loop ended without processing the last block
    // (This will be a duplicate if already added in the loop)
    continuousBlocks.push({
      start: currentBlockStart,
      end: new Date(
        moment(currentBlockStart)
          .add(requiredDurationMinutes, "minutes")
          .valueOf()
      ),
      title: `Available for ${requiredDurationMinutes} mins`,
      background: "#c9e8d1",
      available: true,
      resource: sortedSlots[0].resource
    });
  }

  // Remove duplicates
  return uniqBy(
    continuousBlocks,
    (slot) => `${moment(slot.start).valueOf()}_${moment(slot.end).valueOf()}`
  );
};
