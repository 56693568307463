import type { UseQueryResult } from "react-query";
import { useQuery } from "react-query";
import { get } from "../../../utilities/restClient";
import type { EntityChangeLog } from "../types";

const CHANGE_LOGS_QUERY_KEY = "entity-change-logs";

export function useGetEntityChangeLogs(entityName: string): UseQueryResult<{
  count: number;
  data: EntityChangeLog[];
}> {
  return useQuery(
    [CHANGE_LOGS_QUERY_KEY, entityName],
    async () => {
      return get(
        `${import.meta.env.REACT_APP_URL_API}/entityChangeLogger/${entityName}`,
        {}
      );
    },
    {
      select: (data) => data.data,
      enabled: !!entityName
    }
  );
}
