import { config, library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {
  faGoogle,
  faLinkedin,
  faHackerrank,
  faMicrosoft,
  faTwitter,
  faWindows,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import {
  faAdjust,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowsAltH,
  faBan,
  faBell,
  faBold,
  faBookOpen,
  faBoxOpen,
  faC,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCalendarTimes,
  faCalendarWeek,
  faChartLine,
  faChartPie,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faClipboard,
  faClipboardCheck,
  faClock,
  faCode,
  faCog,
  faCogs,
  faConciergeBell,
  faDatabase,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faEnvelopeOpenText,
  faEraser,
  faExclamation,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faHammer,
  faHistory,
  faHourglassHalf,
  faIdBadge,
  faInfoCircle,
  faItalic,
  faKey,
  faLayerGroup,
  faLegal,
  faListOl,
  faListUl,
  faLock,
  faLongArrowRight,
  faMapMarked,
  faMinusCircle,
  faPaintRoller,
  faPaperclip,
  faPaperPlane,
  faPassport,
  faPhone,
  faPhoneSquareAlt,
  faPlus,
  faPlusCircle,
  faProjectDiagram,
  faQuestionCircle,
  faQuoteRight,
  faRedo,
  faRobot,
  faSearch,
  faShareAlt,
  faShoePrints,
  faStopwatch,
  faSync,
  faSyncAlt,
  faTable,
  faTimesCircle,
  faTools,
  faTrashAlt,
  faTrashRestoreAlt,
  faUnderline,
  faUpload,
  faUser,
  faBoltLightning,
  faUserCircle,
  faUserClock,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUsersCog,
  faUserSecret,
  faUserTie,
  faVideo,
  faUserMinus,
  faScrewdriverWrench
} from "@fortawesome/free-solid-svg-icons";

import {
  faCheckCircle as faCheckCircleDuo,
  faCircleNotch,
  faClock as faClockDuo,
  faQuestionCircle as faQuestionCircleDuo,
  faRepeat as faRepeatDuo,
  faTimesCircle as faTimesCircleDuo,
  faUserCircle as faUserCircleDuo,
  faWindowMaximize as faWindowMaximizeDuo,
  faWindowMinimize as faWindowMinimizeDuo
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faDownload as faDownloadLight,
  faExclamationCircle as faExclamationCircleLight,
  faTimes as faTimesLight,
  faUpload as faUploadLight,
  faUser as faUserLight,
  faBoltLightning as faBoltLightningLight,
  faOctagon as faOctagonLight,
  faCalendarPlus as faCalendarPlusLight,
  faAdjust as faAdjustLight,
  faArrowRight as faArrowRightLight,
  faArrowRightArrowLeft as faArrowRightArrowLeftLight,
  faArrowsAltH as faArrowsAltHLight,
  faBan as faBanLight,
  faBell as faBellLight,
  faBold as faBoldLight,
  faBookOpen as faBookOpenLight,
  faBoxOpen as faBoxOpenLight,
  faC as faCLight,
  faCalendar as faCalendarLight,
  faCalendarAlt as faCalendarAltLight,
  faCalendarCheck as faCalendarCheckLight,
  faCalendarDay as faCalendarDayLight,
  faCalendarTimes as faCalendarTimesLight,
  faCalendarWeek as faCalendarWeekLight,
  faChartLine as faChartLineLight,
  faChartPie as faChartPieLight,
  faCheckCircle as faCheckCircleLight,
  faCheckDouble as faCheckDoubleLight,
  faCheckSquare as faCheckSquareLight,
  faClipboard as faClipboardLight,
  faClipboardCheck as faClipboardCheckLight,
  faClock as faClockLight,
  faCode as faCodeLight,
  faCog as faCogLight,
  faCogs as faCogsLight,
  faConciergeBell as faConciergeBellLight,
  faDatabase as faDatabaseLight,
  faDoorClosed as faDoorClosedLight,
  faDoorOpen as faDoorOpenLight,
  faEnvelopeOpenText as faEnvelopeOpenTextLight,
  faEraser as faEraserLight,
  faExclamation as faExclamationLight,
  faEyeSlash as faEyeSlashLight,
  faFile as faFileLight,
  faFileAlt as faFileAltLight,
  faFileArchive as faFileArchiveLight,
  faFileAudio as faFileAudioLight,
  faFileCode as faFileCodeLight,
  faFileCsv as faFileCsvLight,
  faFileDownload as faFileDownloadLight,
  faFileExcel as faFileExcelLight,
  faFileImage as faFileImageLight,
  faFilePdf as faFilePdfLight,
  faFilePowerpoint as faFilePowerpointLight,
  faFileUpload as faFileUploadLight,
  faFileVideo as faFileVideoLight,
  faFileWord as faFileWordLight,
  faHammer as faHammerLight,
  faHistory as faHistoryLight,
  faHourglassHalf as faHourglassHalfLight,
  faIdBadge as faIdBadgeLight,
  faInfoCircle as faInfoCircleLight,
  faItalic as faItalicLight,
  faKey as faKeyLight,
  faLayerGroup as faLayerGroupLight,
  faLegal as faLegalLight,
  faListOl as faListOlLight,
  faListUl as faListUlLight,
  faLock as faLockLight,
  faLongArrowRight as faLongArrowRightLight,
  faMapMarked as faMapMarkedLight,
  faMinusCircle as faMinusCircleLight,
  faPaintRoller as faPaintRollerLight,
  faPaperclip as faPaperclipLight,
  faPaperPlane as faPaperPlaneLight,
  faPassport as faPassportLight,
  faPhone as faPhoneLight,
  faPhoneSquareAlt as faPhoneSquareAltLight,
  faPlus as faPlusLight,
  faPlusCircle as faPlusCircleLight,
  faProjectDiagram as faProjectDiagramLight,
  faQuestionCircle as faQuestionCircleLight,
  faQuoteRight as faQuoteRightLight,
  faRedo as faRedoLight,
  faRobot as faRobotLight,
  faSearch as faSearchLight,
  faShareAlt as faShareAltLight,
  faShoePrints as faShoePrintsLight,
  faStopwatch as faStopwatchLight,
  faSync as faSyncLight,
  faSyncAlt as faSyncAltLight,
  faTable as faTableLight,
  faTimesCircle as faTimesCircleLight,
  faTools as faToolsLight,
  faTrashAlt as faTrashAltLight,
  faTrashRestoreAlt as faTrashRestoreAltLight,
  faUnderline as faUnderlineLight,
  faUserCircle as faUserCircleLight,
  faUserClock as faUserClockLight,
  faUserEdit as faUserEditLight,
  faUserFriends as faUserFriendsLight,
  faUserPlus as faUserPlusLight,
  faUsers as faUsersLight,
  faUsersCog as faUsersCogLight,
  faUserSecret as faUserSecretLight,
  faUserTie as faUserTieLight,
  faVideo as faVideoLight,
  faUserMinus as faUserMinusLight,
  faMailBulk as faMailBulkLight,
  faAlarmClock as faAlarmClockLight,
  faAlarmPlus as faAlarmPlusLight,
  faAnalytics as faAnalyticsLight,
  faAngleDoubleDown as faAngleDoubleDownLight,
  faAngleDoubleLeft as faAngleDoubleLeftLight,
  faAngleDoubleUp as faAngleDoubleUpLight,
  faAngleDown as faAngleDownLight,
  faAngleUp as faAngleUpLight,
  faArchive as faArchiveLight,
  faArrowAltRight as faArrowAltRightLight,
  faArrowsAltV as faArrowsAltVLight,
  faArrowsH as faArrowsHLight,
  faBellExclamation as faBellExclamationLight,
  faBellOn as faBellOnLight,
  faBellPlus as faBellPlusLight,
  faBells as faBellsLight,
  faBellSchool as faBellSchoolLight,
  faBellSchoolSlash as faBellSchoolSlashLight,
  faBellSlash as faBellSlashLight,
  faBooks as faBooksLight,
  faBriefcase as faBriefcaseLight,
  faBuilding as faBuildingLight,
  faBusinessTime as faBusinessTimeLight,
  faCalendarCircleUser as faCalendarCircleUserLight,
  faCalendarEdit as faCalendarEditLight,
  faCalendarExclamation as faCalendarExclamationLight,
  faCalendarMinus as faCalendarMinusLight,
  faCalendarStar as faCalendarStarLight,
  faChalkboardTeacher as faChalkboardTeacherLight,
  faChartNetwork as faChartNetworkLight,
  faCircleInfo as faCircleInfoLight,
  faClipboardList as faClipboardListLight,
  faClipboardListCheck as faClipboardListCheckLight,
  faClipboardUser as faClipboardUserLight,
  faClone as faCloneLight,
  faCloud as faCloudLight,
  faCloudDownload as faCloudDownloadLight,
  faCloudRainbow as faCloudRainbowLight,
  faClouds as faCloudsLight,
  faCloudUpload as faCloudUploadLight,
  faColumns as faColumnsLight,
  faComment as faCommentLight,
  faCommentAlt as faCommentAltLight,
  faCommentAltCheck as faCommentAltCheckLight,
  faCommentAltEdit as faCommentAltEditLight,
  faCommentAltLines as faCommentAltLinesLight,
  faCommentAltSlash as faCommentAltSlashLight,
  faCommentExclamation as faCommentExclamationLight,
  faCommentLines as faCommentLinesLight,
  faComments as faCommentsLight,
  faCommentSlash as faCommentSlashLight,
  faCompressAlt as faCompressAltLight,
  faCopy as faCopyLight,
  faEdit as faEditLight,
  faEnvelope as faEnvelopeLight,
  faExchange as faExchangeLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faExpandAlt as faExpandAltLight,
  faExternalLink as faExternalLinkLight,
  faEye as faEyeLight,
  faFileCertificate as faFileCertificateLight,
  faFileExport as faFileExportLight,
  faFileInvoice as faFileInvoiceLight,
  faFilePlus as faFilePlusLight,
  faFileSignature as faFileSignatureLight,
  faFileSpreadsheet as faFileSpreadsheetLight,
  faFileUser as faFileUserLight,
  faFlag as faFlagLight,
  faFlaskPotion as faFlaskPotionLight,
  faGlobe as faGlobeLight,
  faGlobeStand as faGlobeStandLight,
  faGripLinesVertical as faGripLinesVerticalLight,
  faH1 as faH1Light,
  faH2 as faH2Light,
  faH3 as faH3Light,
  faH4 as faH4Light,
  faHorizontalRule as faHorizontalRuleLight,
  faImagePolaroid as faImagePolaroidLight,
  faLanguage as faLanguageLight,
  faLayerPlus as faLayerPlusLight,
  faLightbulbOn as faLightbulbOnLight,
  faLineColumns as faLineColumnsLight,
  faLineHeight as faLineHeightLight,
  faLink as faLinkLight,
  faLocationCircle as faLocationCircleLight,
  faLockAlt as faLockAltLight,
  faLockOpenAlt as faLockOpenAltLight,
  faMapMarkerAlt as faMapMarkerAltLight,
  faMapMarkerEdit as faMapMarkerEditLight,
  faMapMarkerMinus as faMapMarkerMinusLight,
  faMapMarkerPlus as faMapMarkerPlusLight,
  faMerge as faMergeLight,
  faMessageBot as faMessageBotLight,
  faMinus as faMinusLight,
  faMinusSquare as faMinusSquareLight,
  faMoneyCheckEdit as faMoneyCheckEditLight,
  faPencil as faPencilLight,
  faPersonCarry as faPersonCarryLight,
  faPhoneOffice as faPhoneOfficeLight,
  faPlay as faPlayLight,
  faPollPeople as faPollPeopleLight,
  faPresentation as faPresentationLight,
  faRandom as faRandomLight,
  faRetweet as faRetweetLight,
  faSave as faSaveLight,
  faScrubber as faScrubberLight,
  faServer as faServerLight,
  faShare as faShareLight,
  faShareAll as faShareAllLight,
  faShareAltSquare as faShareAltSquareLight,
  faShoppingCart as faShoppingCartLight,
  faShovel as faShovelLight,
  faSignInAlt as faSignInAltLight,
  faSignOut as faSignOutLight,
  faSignOutAlt as faSignOutAltLight,
  faSitemap as faSitemapLight,
  faSlash as faSlashLight,
  faSlidersVSquare as faSlidersVSquareLight,
  faSms as faSmsLight,
  faSpinner as faSpinnerLight,
  faSpinnerThird as faSpinnerThirdLight,
  faSquare as faSquareLight,
  faStickyNote as faStickyNoteLight,
  faSuitcase as faSuitcaseLight,
  faSun as faSunLight,
  faSunDust as faSunDustLight,
  faSunrise as faSunriseLight,
  faSunset as faSunsetLight,
  faTachometerAltFast as faTachometerAltFastLight,
  faTasks as faTasksLight,
  faTasksAlt as faTasksAltLight,
  faTelescope as faTelescopeLight,
  faTextWidth as faTextWidthLight,
  faTh as faThLight,
  faTrophy as faTrophyLight,
  faTrophyAlt as faTrophyAltLight,
  faUmbrellaBeach as faUmbrellaBeachLight,
  faUndo as faUndoLight,
  faUndoAlt as faUndoAltLight,
  faUnlink as faUnlinkLight,
  faUnlockAlt as faUnlockAltLight,
  faUserCog as faUserCogLight,
  faUserCrown as faUserCrownLight,
  faUserHardHat as faUserHardHatLight,
  faUserLock as faUserLockLight,
  faUsersClass as faUsersClassLight,
  faUserShield as faUserShieldLight,
  faUsersMedical as faUsersMedicalLight,
  faUserTag as faUserTagLight,
  faVial as faVialLight,
  faWandMagic as faWandMagicLight,
  faWarning as faWarningLight,
  faWebcam as faWebcamLight,
  faWebcamSlash as faWebcamSlashLight,
  faWindowClose as faWindowCloseLight,
  faWindowMaximize as faWindowMaximizeLight,
  faSparkles as faSparklesLight,
  faTags as faTagsLight,
  faScrewdriverWrench as faScrewdriverWrenchLight,
  faSidebar as faSidebarLight,
  faTableColumns as faTableColumnsLight,
  faBoxesStacked as faBoxesStackedLight,
  faCalendarLines as faCalendarLinesLight,
  faCircleQuestion as faCircleQuestionLight
} from "@fortawesome/pro-light-svg-icons";
import {
  faBuilding as faBuildingRegular,
  faExclamationCircle,
  faSquare as faSquareRegular
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAlarmClock,
  faAlarmPlus,
  faAnalytics,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleUp,
  faAngleDown,
  faAngleUp,
  faArchive,
  faArrowAltRight,
  faArrowsAltV,
  faArrowsH,
  faBellExclamation,
  faBellOn,
  faBellPlus,
  faBells,
  faBellSchool,
  faBellSchoolSlash,
  faBellSlash,
  faBooks,
  faBriefcase,
  faBuilding,
  faBusinessTime,
  faCalendarCircleUser,
  faCalendarEdit,
  faCalendarExclamation,
  faCalendarMinus,
  faCalendarStar,
  faChalkboardTeacher,
  faChartNetwork,
  faChevronCircleDown,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faClipboardList,
  faClipboardListCheck,
  faClipboardUser,
  faClone,
  faCloud,
  faCloudDownload,
  faCloudRainbow,
  faClouds,
  faCloudUpload,
  faColumns,
  faComment,
  faCommentAlt,
  faCommentAltCheck,
  faCommentAltEdit,
  faCommentAltLines,
  faCommentAltSlash,
  faCommentExclamation,
  faCommentLines,
  faComments,
  faCommentSlash,
  faCompressAlt,
  faCopy,
  faEdit,
  faEnvelope,
  faExchange,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLink,
  faEye,
  faFileCertificate,
  faFileExport,
  faFileInvoice,
  faFilePlus,
  faFileSignature,
  faFileSpreadsheet,
  faFileUser,
  faFlag,
  faFlaskPotion,
  faGlobe,
  faGlobeStand,
  faGripLinesVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHorizontalRule,
  faImagePolaroid,
  faLanguage,
  faLayerPlus,
  faLightbulbOn,
  faLineColumns,
  faLineHeight,
  faLink,
  faLocationCircle,
  faLockAlt,
  faLockOpenAlt,
  faMailBulk,
  faMapMarkerAlt,
  faMapMarkerEdit,
  faMapMarkerMinus,
  faMapMarkerPlus,
  faMerge,
  faMessageBot,
  faMinus,
  faMinusSquare,
  faMoneyCheckEdit,
  faOctagon,
  faPencil,
  faPersonCarry,
  faPhoneOffice,
  faPlay,
  faPollPeople,
  faPresentation,
  faRandom,
  faRetweet,
  faSave,
  faScrubber,
  faServer,
  faShare,
  faShareAll,
  faShareAltSquare,
  faShoppingCart,
  faShovel,
  faSignInAlt,
  faSignOut,
  faSignOutAlt,
  faSitemap,
  faSlash,
  faSlidersVSquare,
  faSms,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faStickyNote,
  faSuitcase,
  faSun,
  faSunDust,
  faSunrise,
  faSunset,
  faTachometerAltFast,
  faTasks,
  faTasksAlt,
  faTelescope,
  faTextWidth,
  faTh,
  faTimes,
  faTrophy,
  faTrophyAlt,
  faUmbrellaBeach,
  faUndo,
  faUndoAlt,
  faUnlink,
  faUnlockAlt,
  faUserCog,
  faUserCrown,
  faUserHardHat,
  faUserLock,
  faUsersClass,
  faUserShield,
  faUsersMedical,
  faUserTag,
  faVial,
  faWandMagic,
  faWarning,
  faWebcam,
  faWebcamSlash,
  faWindowClose,
  faWindowMaximize
} from "@fortawesome/pro-solid-svg-icons";

export const registerIcons = () => {
  config.autoAddCss = true;
  library.add(
    faBookOpen,
    faCircle,
    faBooks,
    faUsers,
    faRobot,
    faUser,
    faBoltLightning,
    faUserCircle,
    faUserClock,
    faTachometerAltFast,
    faCog,
    faCogs,
    faEnvelopeOpenText,
    faCircle,
    faScrubber,
    faAdjust,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleDown,
    faCheckCircle,
    faBusinessTime,
    faH1,
    faH2,
    faH3,
    faH4,
    faQuoteRight,
    faListUl,
    faListOl,
    faCode,
    faUnderline,
    faItalic,
    faBold,
    faTextWidth,
    faSignInAlt,
    faUserShield,
    faUserSecret,
    faKey,
    faUserHardHat,
    faTools,
    faPaintRoller,
    faHammer,
    faShovel,
    faGoogle,
    faMicrosoft,
    faWindows,
    faWhatsapp,
    faUserTie,
    faMailBulk,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarDay,
    faCalendarEdit,
    faCalendarExclamation,
    faCalendarMinus,
    faCalendarPlus,
    faCalendarStar,
    faCalendarTimes,
    faCalendarWeek,
    faGlobe,
    faEnvelope,
    faUserPlus,
    faLock,
    faLockAlt,
    faUnlockAlt,
    faLockOpenAlt,
    faUsersCog,
    faRedo,
    faClock,
    faLineHeight,
    faFlaskPotion,
    faFile,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileAlt,
    faFileCode,
    faFileCsv,
    faFilePdf,
    faFileWord,
    faFilePowerpoint,
    faFileExcel,
    faFileArchive,
    faFileSpreadsheet,
    faArchive,
    faSignOut,
    faSignOutAlt,
    faWindowClose,
    faPlay,
    faTasksAlt,
    faSpinner,
    faLink,
    faUnlink,
    faTimesCircle,
    faSunset,
    faSunrise,
    faUserFriends,
    faPollPeople,
    faPersonCarry,
    faBuilding,
    faUserTag,
    faWebcam,
    faWebcamSlash,
    faVideo,
    faUserMinus,
    faArrowRightArrowLeft,
    faC,
    faLegal,
    faMoneyCheckEdit,
    faStopwatch,
    faInfoCircle,
    faPhoneSquareAlt,
    faLocationCircle,
    faGlobe,
    faDoorOpen,
    faDoorClosed,
    faPhoneOffice,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faAngleDoubleLeft,
    faTh,
    faClipboardUser,
    faFileUser,
    faSlidersVSquare,
    faCloudDownload,
    faTable,
    faTasks,
    faDatabase,
    faRetweet,
    faProjectDiagram,
    faChartNetwork,
    faConciergeBell,
    faCloudUpload,
    faFileUpload,
    faFileDownload,
    faClipboardList,
    faClipboardListCheck,
    faImagePolaroid,
    faPassport,
    faShare,
    faShareAlt,
    faShareAltSquare,
    faUserCog,
    faClouds,
    faFileCertificate,
    faBan,
    faEyeSlash,
    faClone,
    faTrashAlt,
    faTrashRestoreAlt,
    faPaperPlane,
    faShareAll,
    faGlobeStand,
    faPhone,
    faMapMarked,
    faSpinnerThird,
    faExclamationTriangle,
    faAlarmPlus,
    faAlarmClock,
    faTimes,
    faPlusCircle,
    faMinusCircle,
    faUserEdit,
    faHistory,
    faEraser,
    faPlus,
    faPencil,
    faExclamation,
    faQuestionCircle,
    faExternalLink,
    faUsersMedical,
    faSearch,
    faLightbulbOn,
    faWindowMaximize,
    faMessageBot,
    faColumns,
    faLineColumns,
    faArrowsH,
    faArrowsAltH,
    faArrowAltRight,
    faArrowRight,
    faAnalytics,
    faChartLine,
    faTrophy,
    faTrophyAlt,
    faUserLock,
    faChartPie,
    faClipboardCheck,
    faClipboard,
    faPresentation,
    faMapMarkerAlt,
    faMapMarkerPlus,
    faMapMarkerMinus,
    faMapMarkerEdit,
    faExchange,
    faEdit,
    faSync,
    faSyncAlt,
    faUndo,
    faUndoAlt,
    faVial,
    faEye,
    faCopy,
    faSitemap,
    faAngleDown,
    faAngleUp,
    faBriefcase,
    faSms,
    faShoppingCart,
    faSlash,
    faFlag,
    faServer,
    faCloud,
    faFilePlus,
    faPaperclip,
    faWandMagic,
    faDownload,
    faUpload,
    faUserCrown,
    faCommentAltLines,
    faBoxOpen,
    faSquare,
    faCheckSquare,
    faFileInvoice,
    faUsersClass,
    faFileExport,
    faFileSignature,
    faCommentAltEdit,
    faCommentAltCheck,
    faRandom,
    faTwitter,
    faChalkboardTeacher,
    faCheckDouble,
    faIdBadge,
    faArrowsAltV,
    faTelescope,
    faStopwatch,
    faGripLinesVertical,
    faComment,
    faComments,
    faCommentLines,
    faLanguage,
    faExpandAlt,
    faCompressAlt,
    faCommentSlash,
    faCommentExclamation,
    faHorizontalRule,
    faMinus,
    faChevronLeft,
    faChevronRight,
    faSun,
    faSunDust,
    faBell,
    faBellOn,
    faBellSlash,
    faBellExclamation,
    faBellPlus,
    faBells,
    faShoePrints,
    faChevronDoubleUp,
    faChevronDoubleDown,
    faEdit,
    faRobot,
    faCommentAlt,
    faCommentAltSlash,
    faChevronCircleDown,
    faShoePrints,
    faSuitcase,
    faLayerGroup,
    faLayerPlus,
    faBellSchool,
    faBellSchoolSlash,
    faHourglassHalf,
    faUmbrellaBeach,
    faChevronDown,
    faChevronUp,
    faMinusSquare,
    faWarning,
    faCircleInfo,
    faCloudRainbow,
    faLinkedin,
    faHackerrank,
    faStickyNote,
    faMerge,
    faArrowRight,
    faLongArrowRight,
    faSave,
    faScrewdriverWrench
  );

  // regular pro icons
  library.add({
    faBuildingRegular,
    faExclamationCircle,
    faCalendarCircleUser,
    faSquareRegular
  });

  // light icons
  library.add({
    faDownloadLight,
    faExclamationCircleLight,
    faTimesLight,
    faUploadLight,
    faUserLight,
    faBoltLightningLight,
    faOctagonLight,
    faCalendarPlusLight,
    faAdjustLight,
    faArrowRightLight,
    faArrowRightArrowLeftLight,
    faArrowsAltHLight,
    faBanLight,
    faBellLight,
    faBoldLight,
    faBookOpenLight,
    faBoxOpenLight,
    faCLight,
    faCalendarLight,
    faCalendarAltLight,
    faCalendarCheckLight,
    faCalendarDayLight,
    faCalendarTimesLight,
    faCalendarWeekLight,
    faChartLineLight,
    faChartPieLight,
    faCheckCircleLight,
    faCheckDoubleLight,
    faCheckSquareLight,
    faClipboardLight,
    faClipboardCheckLight,
    faClockLight,
    faCodeLight,
    faCogLight,
    faCogsLight,
    faConciergeBellLight,
    faDatabaseLight,
    faDoorClosedLight,
    faDoorOpenLight,
    faEnvelopeOpenTextLight,
    faEraserLight,
    faExclamationLight,
    faEyeSlashLight,
    faFileLight,
    faFileAltLight,
    faFileArchiveLight,
    faFileAudioLight,
    faFileCodeLight,
    faFileCsvLight,
    faFileDownloadLight,
    faFileExcelLight,
    faFileImageLight,
    faFilePdfLight,
    faFilePowerpointLight,
    faFileUploadLight,
    faFileVideoLight,
    faFileWordLight,
    faHammerLight,
    faHistoryLight,
    faHourglassHalfLight,
    faIdBadgeLight,
    faInfoCircleLight,
    faItalicLight,
    faKeyLight,
    faLayerGroupLight,
    faLegalLight,
    faListOlLight,
    faListUlLight,
    faLockLight,
    faLongArrowRightLight,
    faMapMarkedLight,
    faMinusCircleLight,
    faPaintRollerLight,
    faPaperclipLight,
    faPaperPlaneLight,
    faPassportLight,
    faPhoneLight,
    faPhoneSquareAltLight,
    faPlusLight,
    faPlusCircleLight,
    faProjectDiagramLight,
    faQuestionCircleLight,
    faQuoteRightLight,
    faRedoLight,
    faRobotLight,
    faSearchLight,
    faShareAltLight,
    faShoePrintsLight,
    faStopwatchLight,
    faSyncLight,
    faSyncAltLight,
    faTableLight,
    faTimesCircleLight,
    faToolsLight,
    faTrashAltLight,
    faTrashRestoreAltLight,
    faUnderlineLight,
    faUserCircleLight,
    faUserClockLight,
    faUserEditLight,
    faUserFriendsLight,
    faUserPlusLight,
    faUsersLight,
    faUsersCogLight,
    faUserSecretLight,
    faUserTieLight,
    faVideoLight,
    faUserMinusLight,
    faMailBulkLight,
    faAlarmClockLight,
    faAlarmPlusLight,
    faAnalyticsLight,
    faAngleDoubleDownLight,
    faAngleDoubleLeftLight,
    faAngleDoubleUpLight,
    faAngleDownLight,
    faAngleUpLight,
    faArchiveLight,
    faArrowAltRightLight,
    faArrowsAltVLight,
    faArrowsHLight,
    faBellExclamationLight,
    faBellOnLight,
    faBellPlusLight,
    faBellsLight,
    faBellSchoolLight,
    faBellSchoolSlashLight,
    faBellSlashLight,
    faBooksLight,
    faBriefcaseLight,
    faBuildingLight,
    faBusinessTimeLight,
    faCalendarCircleUserLight,
    faCalendarEditLight,
    faCalendarExclamationLight,
    faCalendarMinusLight,
    faCalendarStarLight,
    faChalkboardTeacherLight,
    faChartNetworkLight,
    faCircleInfoLight,
    faClipboardListLight,
    faClipboardListCheckLight,
    faClipboardUserLight,
    faCloneLight,
    faCloudLight,
    faCloudDownloadLight,
    faCloudRainbowLight,
    faCloudsLight,
    faCloudUploadLight,
    faColumnsLight,
    faCommentLight,
    faCommentAltLight,
    faCommentAltCheckLight,
    faCommentAltEditLight,
    faCommentAltLinesLight,
    faCommentAltSlashLight,
    faCommentExclamationLight,
    faCommentLinesLight,
    faCommentsLight,
    faCommentSlashLight,
    faCompressAltLight,
    faCopyLight,
    faEditLight,
    faEnvelopeLight,
    faExchangeLight,
    faExclamationTriangleLight,
    faExpandAltLight,
    faExternalLinkLight,
    faEyeLight,
    faFileCertificateLight,
    faFileExportLight,
    faFileInvoiceLight,
    faFilePlusLight,
    faFileSignatureLight,
    faFileSpreadsheetLight,
    faFileUserLight,
    faFlagLight,
    faFlaskPotionLight,
    faGlobeLight,
    faGlobeStandLight,
    faGripLinesVerticalLight,
    faH1Light,
    faH2Light,
    faH3Light,
    faH4Light,
    faHorizontalRuleLight,
    faImagePolaroidLight,
    faLanguageLight,
    faLayerPlusLight,
    faLightbulbOnLight,
    faLineColumnsLight,
    faLineHeightLight,
    faLinkLight,
    faLocationCircleLight,
    faLockAltLight,
    faLockOpenAltLight,
    faMapMarkerAltLight,
    faMapMarkerEditLight,
    faMapMarkerMinusLight,
    faMapMarkerPlusLight,
    faMergeLight,
    faMessageBotLight,
    faMinusLight,
    faMinusSquareLight,
    faMoneyCheckEditLight,
    faPencilLight,
    faPersonCarryLight,
    faPhoneOfficeLight,
    faPlayLight,
    faPollPeopleLight,
    faPresentationLight,
    faRandomLight,
    faRetweetLight,
    faSaveLight,
    faScrubberLight,
    faServerLight,
    faShareLight,
    faShareAllLight,
    faShareAltSquareLight,
    faShoppingCartLight,
    faShovelLight,
    faSignInAltLight,
    faSignOutLight,
    faSignOutAltLight,
    faSitemapLight,
    faSlashLight,
    faSlidersVSquareLight,
    faSmsLight,
    faSpinnerLight,
    faSpinnerThirdLight,
    faSquareLight,
    faStickyNoteLight,
    faSuitcaseLight,
    faSunLight,
    faSunDustLight,
    faSunriseLight,
    faSunsetLight,
    faTachometerAltFastLight,
    faTasksLight,
    faTasksAltLight,
    faTelescopeLight,
    faTextWidthLight,
    faThLight,
    faTrophyLight,
    faTrophyAltLight,
    faUmbrellaBeachLight,
    faUndoLight,
    faUndoAltLight,
    faUnlinkLight,
    faUnlockAltLight,
    faUserCogLight,
    faUserCrownLight,
    faUserHardHatLight,
    faUserLockLight,
    faUsersClassLight,
    faUserShieldLight,
    faUsersMedicalLight,
    faUserTagLight,
    faVialLight,
    faWandMagicLight,
    faWarningLight,
    faWebcamLight,
    faWebcamSlashLight,
    faWindowCloseLight,
    faWindowMaximizeLight,
    faSparklesLight,
    faTagsLight,
    faSidebarLight,
    faTableColumnsLight,
    faBoxesStackedLight,
    faScrewdriverWrenchLight,
    faCalendarLinesLight,
    faCircleQuestionLight
  });

  // duotone icons
  library.add({
    faUserCircle,
    faCircleNotch,
    faQuestionCircleDuo,
    faTimesCircleDuo,
    faClockDuo,
    faUserCircleDuo,
    faCheckCircleDuo,
    faWindowMaximizeDuo,
    faWindowMinimizeDuo,
    faRepeatDuo
  });
};
