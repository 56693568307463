export const HOME = "/home";
export const RESPOND = "/respond/:mode/:token";
export const TOKEN = "/token";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const SIGNIN = "/signin";
export const SIGNOUT = "/signout";
export const LOGOUT = "/logout";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/forgot-password";
export const SETTINGS = "/settings";

export const PROSPECTLINKS_BOOKINGS = "/prospectlink-bookings";
export const PROSPECTLINKS_DASHBOARD = "/prospectlinks";

export const ROOSTERLINKS_BOOKINGS = "/roosterlink-bookings";
export const ROOSTERLINKS_DASHBOARD = "/roosterlinks";

export const USERS = "/users";
export const USER = "/users/:id";
export const USER_INVITE = "/users/invite";
export const INTERVIEWERPREFERENCES = "/interviewer/preferences";
export const INTERVIEWERSDASHBOARD = "/interviewers";
export const INTERVIEWERS_LIST = "/interviewers-list";
export const INTERVIEWERS_AVAILABILITY = "/interviewers-availability";

export const TAGS_DASHBOARD = "/tags";

export const CANDIDATECALENDAR = "/candidate/availability/:availabilityID";
export const ROOSTER_LINK = "/me/:personIdentifier/:profileIdentifier";
export const PROSPECTS = "/meetings/:personIdentifier/:profileIdentifier";

export const JOBS = "/jobs";
export const CANDIDATES = "/candidates";
export const APPLICATION = "/application";
export const APPLICATION_ID = "/application/:id";

export const INTERVIEWS_CALENDAR = "/interviews/calendar";

export const SCHEDULER_BASE =
  "/application/:applicationId/interview/schedule/:id";
export const AUTOSCHEDULER = `${SCHEDULER_BASE}/calendar`; // step 1
export const EMAIL = `${SCHEDULER_BASE}/email`; // step 2
export const REVIEW = `${SCHEDULER_BASE}/review`; // step 3
export const SEND = `${SCHEDULER_BASE}/send`; // step 4

export const EMAILS = "/emails";
export const EMAILTEMPLATES = "/emailtemplates";
export const EMAILTEMPLATESEDIT = "/emailtemplatesedit/:id";

export const PREFERENCES = "/preferences";
export const MYINTERVIEWPREFERENCES = "/interviewer/preferences";

export const LOGOUPLOAD = "/logo/upload";

export const SUPPORT = "/support";
export const PRIVACYPOLICY = "/privacy-policy";
export const TERMSANDCONDITIONS = "/terms-and-conditions";
export const GOOGLEDISCLOSURE = "/google-disclosure";
export const GOOGLESCOPES = "/google-scopes";
export const ZOOMSUPPORT = "/zoom";
export const WEBEXSUPPORT = "/webex";
export const DOCUMENTATION = "/documentation";
export const CONFIGURATION = "/configuration";

export const PLATFORM = "/platform";
export const INTEGRATIONS = "/integrations";
export const ABOUT = "/about";
export const CONTACT = "/contact";
export const THANKYOU = "/thank-you";
export const NOTAUTHORIZED = "/not-authorized";
export const LINKUNAVAILABLE = "/link-unavailable";
export const JOBUNAVAILABLE = "/job-unavailable";

export const ANALYTICS = "/analytics";

export const DEMOVIDEO = "/video/demo";
export const TRAININGVIDEO = "/video/training";

export const REPORTS = "/reports";

// hidden pages only for Kog tenant admins
export const CLIENTMIGRATION = "/client-migration";
export const TRANSLATIONMANAGEMENT = "/translation-management";
export const CLIENTDASHBOARD = "/client-dashboard";
