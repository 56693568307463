import { FC, ReactNode } from "react";
import { Drawer, DrawerProps } from "@mui/material";
import { SxProps } from "@mui/material";

type TAnchorDirection = "left" | "right" | "bottom" | "top";

interface IDrawerComponentProps extends DrawerProps {
  anchorDirection: TAnchorDirection;
  handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

export const DrawerComponent: FC<IDrawerComponentProps> = props => {
  const { open, anchorDirection, sx, handleClose, children, ...rest } = props;
  // const customStyles: SxProps={
  //   paddingTop:"4rem",
  //   minWidth:"300px"
  // }
  // const styles:SxProps = Object.assign(sx,customStyles)

  return (
    <Drawer
      open={open}
      transitionDuration={0}
      anchor={anchorDirection}
      sx={{ ...sx }}
      PaperProps={{
        sx: { ...sx }
      }}
      onClose={handleClose}
      {...rest}
    >
      {children}
    </Drawer>
  );
};
