import { AxiosResponse } from "axios";
import { useCallback } from "react";
import { Prisma } from "@prisma/client";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  useQuery
} from "react-query";
import { post, put, get } from "../utilities/restClient";
import { getServerUrl } from "../constants";
import { clientFeatureStore } from "../store/client-features-store";
import { userPermissionStore } from "../store/user-permissions-store";

const PERMISSION = "/tenants/permissions";
const FEATURES = "/tenants/features";
const MY_PERMISSION = "/users/me/permissions";

type InputUpdatePermission = {
  permission: Prisma.PermissionsUpdateInput;
  action: string;
};

export const useUpdatePermission = (
  onUpdatePermissionSuccess?: () => void,
  onUpdatePermissionError?: () => void
): UseMutationResult<AxiosResponse, unknown, InputUpdatePermission> => {
  return useMutation(
    ({ permission, action }: InputUpdatePermission) => {
      return put(`${getServerUrl()}${PERMISSION}`, {
        permission,
        action
      });
    },
    {
      onSuccess: async () => {
        if (onUpdatePermissionSuccess) {
          await onUpdatePermissionSuccess();
        }
      },
      onError: () => {
        if (onUpdatePermissionError) {
          onUpdatePermissionError();
        }
      }
    }
  );
};

export const useFetchPermissions = () => {
  const queryKey = ["permissions"] as const; // Using array for better type safety
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch, isStale, isRefetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await get(`${getServerUrl()}${PERMISSION}`);
      return response.data;
    },
    staleTime: 60000,
    cacheTime: 60000
  });

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey,
      exact: true
    });
  }, [queryClient]);

  return {
    data,
    error,
    isLoading,
    isStale,
    isRefetching,
    invalidate,
    refetch
  } as const;
};

export const useFetchMyPermissions = (
  {
    enabled = true,
    onFetchMyPermissionSuccess,
    onFetchMyPermissionError
  } = {} as {
    enabled?: boolean;
    onFetchMyPermissionSuccess?: () => void;
    onFetchMyPermissionError?: () => void;
  }
) => {
  const queryKey = ["my-permissions"] as const; // Using array for better type safety
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch, isStale, isRefetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await get(`${getServerUrl()}${MY_PERMISSION}`);
      return response.data;
    },
    staleTime: 60000,
    cacheTime: 60000,
    enabled,
    onSuccess: (data) => {
      if (onFetchMyPermissionSuccess) {
        onFetchMyPermissionSuccess(data);
      }
    },
    onError: () => {
      if (onFetchMyPermissionError) {
        onFetchMyPermissionError();
      }
    }
  });

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey,
      exact: true
    });
  }, [queryClient]);

  return {
    data,
    error,
    isLoading,
    isStale,
    isRefetching,
    invalidate,
    refetch
  } as const;
};

export const useUpdateClientFeatures = (
  onUpdateClientFeaturesSuccess?: () => void,
  onUpdateClientFeaturesError?: () => void
): UseMutationResult<AxiosResponse, unknown, Record<string, boolean>> => {
  return useMutation(
    (features: Record<string, boolean>) => {
      return put(`${getServerUrl()}${FEATURES}`, {
        features
      });
    },
    {
      onSuccess: async () => {
        if (onUpdateClientFeaturesSuccess) {
          await onUpdateClientFeaturesSuccess();
        }
      },
      onError: () => {
        if (onUpdateClientFeaturesError) {
          onUpdateClientFeaturesError();
        }
      }
    }
  );
};

export const useClientFeatures = (
  { enabled = true, onClientFeaturesSuccess, onClientFeaturesError } = {} as {
    enabled?: boolean;
    onClientFeaturesSuccess?: () => void;
    onClientFeaturesError?: () => void;
  }
) => {
  const queryKey = ["client-features"] as const; // Using array for better type safety
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch, isStale, isRefetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await get(`${getServerUrl()}${FEATURES}`);
      return response.data;
    },
    staleTime: 60000,
    cacheTime: 60000,
    enabled,
    onSuccess: (data) => {
      if (onClientFeaturesSuccess) {
        onClientFeaturesSuccess(data);
      }
    },
    onError: () => {
      if (onClientFeaturesError) {
        onClientFeaturesError();
      }
    }
  });

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey,
      exact: true
    });
  }, [queryClient]);

  return {
    data,
    error,
    isLoading,
    isStale,
    isRefetching,
    invalidate,
    refetch
  } as const;
};

export const useMyPermissions = () => {
  const userPermissions = userPermissionStore((state) => state.permissions);
  const clientFeatures = clientFeatureStore((state) => state.clientFeatures);
  const hasClientFeature = clientFeatureStore(
    (state) => state.hasClientFeature
  );

  const canUserSomeAction = useCallback(
    ({ feature, actions }) => {
      return (
        hasClientFeature(feature) &&
        actions.some((action) =>
          userPermissions?.some((permission) => permission?.action === action)
        )
      );
    },
    [hasClientFeature, userPermissions, clientFeatures]
  );

  const canUserEveryAction = useCallback(
    ({ feature, actions }) => {
      return (
        hasClientFeature(feature) &&
        actions.every((action) =>
          userPermissions?.some((permission) => permission?.action === action)
        )
      );
    },
    [hasClientFeature, userPermissions, clientFeatures]
  );

  return {
    canUserSomeAction,
    canUserEveryAction,
    hasClientFeature,
    userPermissions,
    clientFeatures
  } as const;
};
