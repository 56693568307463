import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  IconButton,
  TextField,
  Box,
  Fade
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { FilterAlt, FilterAltOutlined, Close } from "@mui/icons-material";
import { useEffect, useState, useMemo, useRef } from "react";
import DiffRenderer from "./entityChangeLog.diffRenderer";
import { useGetEntityChangeLogs } from "./services/hooks";
import { get, pick } from "lodash";

interface Column {
  id: string;
  label: string;
  sortable?: boolean;
  searchable?: boolean;
  width?: string;
  render?: (row: any) => React.ReactNode;
}

const SearchOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 2,
  top: "50%",
  transform: "translate(-5%, -50%)",
  left: "50%",
  padding: theme.spacing(1),
  backgroundColor: "#fbfbfc",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  minWidth: "250px",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  transition: "all 0.3s ease-in-out"
}));

const ChangeLoggerModal = ({ open, onClose, entityName, columns }) => {
  const { t } = useTranslation();
  const {
    data: changes,
    isFetching,
    error,
    refetch
  } = useGetEntityChangeLogs(entityName);

  const tableColumns = useMemo(() => {
    if (columns) {
      return columns;
    }

    return [
      {
        id: "performedBy",
        label: t("ROOSTER.COMMON.PERFORMED-BY", {
          defaultValue: "Performed By"
        }),
        sortable: true,
        searchable: true,
        width: "30%"
      },
      {
        id: "timestamp",
        label: t("ROOSTER.HISTORY.DATE-TIME", { defaultValue: "Date/Time" }),
        sortable: true,
        render: (row) => new Date(get(row, "timestamp")).toLocaleString(),
        width: "20%"
      },
      {
        id: "operationType",
        label: t("ROOSTER.AUTOMATION.ACTION", { defaultValue: "Action" }),
        sortable: true,
        width: "15%"
      },
      {
        id: "diff",
        label: t("ROOSTER.COMMON.CHANGE", { defaultValue: "Change" }),
        width: "35%",
        render: (row) => {
          const { diff, collection } = pick(row, ["diff", "collection"]);
          return <DiffRenderer diff={diff} collection={collection} />;
        }
      }
    ];
  }, [columns, t]);

  const searchOverlayRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        activeFilter &&
        searchOverlayRef.current &&
        !searchOverlayRef.current?.contains(event.target) &&
        // Ignore clicks on the filter button itself
        !event.target.closest("[data-filter-button]")
      ) {
        setActiveFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeFilter]);

  // Reset states when modal opens
  useEffect(() => {
    if (open) {
      // Reset pagination
      setPage(0);
      setRowsPerPage(5);
      // Reset sorting
      setOrder("desc");
      setOrderBy("timestamp");
      // Reset search filters
      setColumnSearches({});
      setActiveFilter(null);
      // Fetch fresh data
      refetch();
    }
  }, [open, refetch]);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState("timestamp"); // Sort field
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const [columnSearches, setColumnSearches] = useState<Record<string, string>>(
    {}
  );

  const toggleFilter = (columnId: string) => {
    setActiveFilter((current) => (current === columnId ? null : columnId));
  };

  const hasActiveSearch = (
    columnId: string,
    searches: Record<string, string>
  ) => {
    return Boolean(searches[columnId]?.trim());
  };

  const handleSearchChange = (columnId: string) => (event) => {
    setColumnSearches((prev) => ({
      ...prev,
      [columnId]: event.target.value
    }));
    setPage(0);
  };

  const processedData = useMemo(() => {
    if (!changes?.data) return { rows: [], totalCount: 0 };

    // Filter based on column-specific searches
    const filteredData = changes.data.filter((row) => {
      return tableColumns.every((column) => {
        if (!column.searchable) return true;
        const searchValue = columnSearches[column.id]?.trim();
        if (!searchValue) return true;

        const value = get(row, column.id);
        return String(value).toLowerCase().includes(searchValue.toLowerCase());
      });
    });

    // Then sort
    const sortedData = [...filteredData].sort((a, b) => {
      const aValue =
        orderBy === "timestamp"
          ? new Date(get(a, orderBy)).getTime()
          : String(get(a, orderBy));
      const bValue =
        orderBy === "timestamp"
          ? new Date(get(b, orderBy)).getTime()
          : String(get(b, orderBy));

      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });

    return {
      rows: sortedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
      totalCount: filteredData.length
    };
  }, [changes?.data, columnSearches, order, orderBy, page, rowsPerPage]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t("ROOSTER.COMMON.CHANGE-LOG", {
          defaultValue: "Change Log"
        })}
      </DialogTitle>
      <DialogContent>
        {isFetching ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">
            {t("ROOSTER.COMMON.FAILED-TO-FETCH-CHANGES", {
              defaultValue: "Failed to fetch changes"
            })}
            : {error}
          </Typography>
        ) : (changes?.count || 0) === 0 ? (
          <Typography>
            {t("ROOSTER.COMMON.NO-CHANGES-RECORDED-FOR-ENTITY", {
              defaultValue: "No changes recorded for this entity."
            })}
          </Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                <TableHead>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          position: "relative",
                          width: column.width,
                          maxWidth: column.width,
                          whiteSpace: "normal",
                          wordWrap: "break-word"
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          {column.label}
                          {column.searchable && (
                            <Box
                              position="relative"
                              sx={{ width: 27, height: 27 }}
                            >
                              <Fade
                                in={!activeFilter || activeFilter !== column.id}
                                timeout={200}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0
                                }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => toggleFilter(column.id)}
                                  data-filter-button
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      color: "rgba(34, 51, 84, 0.7)",
                                      strokeWidth: 0.8,
                                      fontSize: "1.2rem"
                                    }
                                  }}
                                >
                                  {hasActiveSearch(
                                    column.id,
                                    columnSearches
                                  ) ? (
                                    <FilterAlt fontSize="small" />
                                  ) : (
                                    <FilterAltOutlined fontSize="small" />
                                  )}
                                </IconButton>
                              </Fade>
                              <Fade
                                in={activeFilter === column.id}
                                timeout={{
                                  enter: 300,
                                  exit: 200
                                }}
                                unmountOnExit
                              >
                                <SearchOverlay ref={searchOverlayRef}>
                                  <TextField
                                    autoFocus
                                    size="small"
                                    variant="outlined"
                                    placeholder={`Search ${column.label}`}
                                    value={columnSearches[column.id] || ""}
                                    onChange={handleSearchChange(column.id)}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        toggleFilter(column.id);
                                      }
                                    }}
                                    sx={{
                                      flex: 1,
                                      "& .MuiOutlinedInput-root": {
                                        backgroundColor: "background.paper"
                                      }
                                    }}
                                  />
                                  <IconButton
                                    size="small"
                                    onClick={() => toggleFilter(column.id)}
                                    sx={{ padding: 0.5 }}
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                </SearchOverlay>
                              </Fade>
                            </Box>
                          )}
                          {column.sortable && (
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleRequestSort(column.id)}
                            />
                          )}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processedData.rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        {tableColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              width: column.width,
                              maxWidth: column.width,
                              whiteSpace: "normal",
                              wordWrap: "break-word"
                            }}
                          >
                            {column.render
                              ? column.render(row)
                              : get(row, column.id)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={processedData.totalCount}
              labelRowsPerPage={t("ROOSTER.COMMON.ROWS-PER-PAGE", {
                defaultValue: "Rows per page:"
              })}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("ROOSTER.COMMON.CLOSE", {
            defaultValue: "Close"
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLoggerModal;
